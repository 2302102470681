.service-ad-container-R {
    margin: 0;
    margin-top: 50px;
    width: 100%;
    height: 450px;
}

.service-ad-flex-R {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.service-ad-text-R {
    margin-left: 80px;
    text-align: left;
    align-self: flex-start;
}

.service-ad-text-R h3 {
    color: #00818a;
    font-weight: normal;
    font-size: 40px;
    margin: auto;
    display: inline;
}

.service-ad-text-R h3:hover {
    color: black;
    cursor: pointer;
}

.service-ad-text-R .linked-header-R {
    padding-top: 80px;
}

.service-ad-text-R p.learn-more-p-R {
    font-weight: normal;
    font-size: 18px;
    display: inline;
    color: #00818a;
}

.service-ad-text-R p.learn-more-p-R:hover {
    color: black;
}

.service-ad-text-R .learn-more-R {
    padding-top: 50px;
    padding-bottom: 40px;
}

.service-ad-img-R {
    max-width: 50%;
}

.service-ad-img-R img {
    max-width: 100%;
    max-height: 100%;
    width: 80%;
    margin-left: 60px;
}

.fade-in {
    opacity: 0;
    transform: translateY(23px);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}
  
.fade-in-active {
    opacity: 1;
    transform: translateY(0);
}

/* **********************************
******  CSS MEDIA QUERIES ********* 
************************************/

/* IMac - 21" */
@media screen and (min-width: 2000px){ 
    .service-ad-img-R img {
        width: 60%;
        margin-left: 30%;
    }

    .service-ad-text-R h3 {
        font-size: 50px;
    }

    p.home-service-paragraph-R {
        font-size: 23px;
    }

    .service-ad-text-R p.learn-more-p-R {
        font-size: 20px;
    }
}

/* IPad */
@media screen and (max-width: 900px) and (min-width: 800px){ 
    .service-ad-container-R {
        margin-top: 150px;
        height: 650px;
        max-width: 100%;
        display: flex;
        justify-content: center;
    }
    
    .service-ad-flex-R {
        height: 100%;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        max-width: 90%;
    }
    
    .service-ad-text-R {
        margin-left: 0px;
        text-align: center;
        align-self: flex-start;
    }
    
    .service-ad-text-R h3 {
        color: #00818a;
        font-weight: normal;
        font-size: 45px;
        margin: 0;
        padding-top: 50px;
    }
    
    .service-ad-text-R p {
        color: black;
        font-weight: normal;
        padding-top: 40px;
        font-size: 22px;
        padding-bottom: 30px;
        max-width: 80%;
        margin: auto;
    }
    
    .service-ad-img-R {
        margin: 0;
        max-width: 80%;
    }
    
    .service-ad-img-R img {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        margin: auto;
    }
}

@media screen and (max-width: 800px) and (min-width: 751px){ 
    .service-ad-container-R {
        margin-top: 120px;
        height: 650px;
        max-width: 100%;
        display: flex;
        justify-content: center;
    }
    
    .service-ad-flex-R {
        height: 100%;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        max-width: 90%;
    }
    
    .service-ad-text-R {
        margin-left: 0px;
        text-align: center;
        align-self: flex-start;
    }
    
    .service-ad-text-R h3 {
        color: #00818a;
        font-weight: normal;
        font-size: 40px;
        margin: 0;
        padding-top: 35px;
    }
    
    .service-ad-text-R p {
        color: black;
        font-weight: normal;
        padding-top: 40px;
        font-size: 18px;
        padding-bottom: 30px;
        max-width: 80%;
        margin: auto;
    }
    
    .service-ad-img-R {
        margin: 0;
        max-width: 90%;
    }
    
    .service-ad-img-R img {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        margin: auto;
    }
}

/* IPhone */
@media screen and (max-width: 750px) { 
    .service-ad-container-R {
        margin-top: 30px;
        height: 650px;
        max-width: 100%;
        display: flex;
        justify-content: center;
    }
    
    .service-ad-flex-R {
        height: 100%;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        max-width: 90%;
    }
    
    .service-ad-text-R {
        margin-left: 0px;
        text-align: center;
        align-self: flex-start;
    }
    
    .service-ad-text-R h3 {
        color: #00818a;
        font-weight: normal;
        font-size: 40px;
        margin: 0;
        padding-top: 35px;
    }
    
    .service-ad-text-R p {
        color: black;
        font-weight: normal;
        padding-top: 40px;
        font-size: 18px;
        padding-bottom: 30px;
        max-width: 80%;
        margin: auto;
    }
    
    .service-ad-img-R {
        margin: 0;
        max-width: 90%;
    }
    
    .service-ad-img-R img {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        margin: auto;
    }
}