.contact-form-container {
    width: 100%;
    background-color: white;
    max-width: 100%;
    height: 875px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-container {
    background-color: rgba(0, 0, 0, 0.85);
    width: 70%;
    height: 625px;
}

.form-container .contact-info { 
    text-align: center;
    margin: 0;
    color: white;
    font-weight: normal;
    padding-top: 30px;
    font-size: 15px;
}

.form-container .contact-email { 
    text-align: center;
    margin: 0;
    color: #00818a;
    font-weight: normal;
    font-size: 15px;
    padding-bottom: 30px;
}

.contact-form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;
}

.contact-form input {
    width: 50%;
    border: none;
    padding: 10px;
    margin-bottom: 15px;
    color: black;
}

.contact-form input::placeholder {
    color: #00818a;
}

.contact-form label {
    color: white;
    font-size: 13px;
    padding-bottom: 5px;
    padding-left: 10px;
}

.contact-form select {
    width: 52%;
    padding: 10px;
    border: none;
    margin-bottom: 15px;
    color: #00818a;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path d='M7 10l5 5 5-5z'/></svg>");
    background-repeat: no-repeat;
    background-position: right 10px center;    
    align-self: center;
}

textarea {
    padding: 10px;
    height: 210px;
    border: none;
    width: 50%;
    font-family: 'Open Sans', sans-serif;
    color: black;
}

textarea::placeholder {
    color: #00818a;
}

.button-container {
    display: flex;
    justify-content: flex-end;
}

.submit-button {
    margin-top: 25px;
    margin-right: 20px;
    height: 30px;
    width: 100px;
    max-width: 20%;
    box-sizing: border-box;
    border-radius: 60px 60px 60px 60px;
    background-color: #00818a;
    border: 1px solid #00818a;
    color: #FFF;
    font-size: 12px;
    letter-spacing: 0.35px;
    text-transform: uppercase;
    line-height: 21px;
    cursor: pointer;
}

.submit-button:hover {
    color: #00818a;
    border: 1px solid #00818a;
    background-color: #FFF;
    border: 2px solid #00818a;
    float: right;
}

/* **********************************
******  CSS MEDIA QUERIES ********* 
************************************/

/* IMac - 21" */
@media screen and (min-width: 2000px){ 
    .contact-form select {
        width: 51%;
    }

    .form-container .contact-email {
        font-size: 18px;
    }

    .form-container .contact-info {
        font-size: 18px;
    }
}

/* IPad */
@media screen and (max-width: 900px) and (min-width: 770px){ 
    .contact-form-container {
        height: 700px;
    }

    .form-container {
        width: 85%;
    }

    .contact-form input {
        width: 70%;
        font-size: 20px;
    }
    
    .contact-form select {
        width: 70%;
        font-size: 20px;
    }

    textarea {
        width: 70%;
        font-size: 20px;
    }

    .form-container .contact-info { 
        font-size: 23px;
    }

    .form-container .contact-email { 
        font-size: 22px;
    }

}

/* IPhone */
@media screen and (max-width: 770px) { 
    .contact-form-container {
        height: 700px;
    }

    .form-container {
        width: 85%;
    }

    .contact-form input {
        width: 70%;
        font-size: 15px;
    }
    
    .contact-form select {
        width: 72%;
        font-size: 15px;
    }

    textarea {
        width: 70%;
        font-size: 15px;
    }

    .form-container .contact-info { 
        font-size: 18px;
    }

    .form-container .contact-email { 
        font-size: 16px;
    }
    
    button.submit-button {
        max-width: 30%;
        font-size: 13px;
        margin-right: 10px;
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
    }
}