.header-home-container {
    margin: auto;
    width: 100%;
    height: 80px;
    background-color: black;
}

.header-home-container nav {
    display: flex; 
    height: 100%;
    margin-left: 40px;
}

.header-home-container img {
    width: 45px;
}

.header-home-container ul {
    margin: auto;
    margin: 0;
    padding: 0;
    display: flex; 
    align-items: center;
}

.not-home-container ul {
    margin-top: 2.5%;
}

.header-home-container ul li {
    margin-top: 8px;
    margin-right: 50px;
    display: inline-block;
    list-style: none;
}

.header-home-container ul li .header-links {
    text-decoration: none;
    color: white;
}

.header-home-container ul li .header-links:hover {
    color: #00818a;
}

.header-home-container ul li .header-links-contact {
    text-decoration: none;
    color: #00818a;
}

.header-home-container ul li .header-links-contact:hover {
    color: white;
}

.header-home-container .offer-banner {
    width: 100%;
    height: 60px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    overflow: hidden;
}

.header-home-container .offer-banner p {
    margin: 0;
    max-width: 120%;
    width: 50%;
    text-align: right;
    overflow: hidden;
}

.offer-banner p {
    animation: rotateBanner 15s linear infinite;
    position: absolute;
    white-space: nowrap;
}
  
@keyframes rotateBanner {
    0% {
      transform: translateX(110%);
    }
    100% {
      transform: translateX(-140%);
    }
}

.header-home-container .accent-color {
    color: #00818a;
}

/* **********************************
******  CSS MEDIA QUERIES ********* 
************************************/

/* IMac - 21" */
@media screen and (min-width: 2000px){ 
    .header-home-container .offer-banner {
        font-size: 18px;
    }

    .header-home-container ul li .header-links {
        font-size: 18px;
    }
}

/* IPad */
@media screen and (max-width: 900px) and (min-width: 770px){ 

    .header-home-container nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 80px;
        margin: auto;
        padding: 0 20px;
    }

    .header-home-container ul {
        display: none;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 80px;
        left: 0;
        width: 100%;
        background-color: #000;
        padding-top: 10px;
    }

    .navbar-toggler-icon {
        background-color: black;
    }

    .header-home-container ul li {
        margin: 0;
        width: 100%;
        padding: 10px 0;
    }

    .header-home-container ul li .header-links {
        color: #fff;
        text-align: center;
    }

    .header-home-container ul li .header-links:hover {
        color: #00818a;
    }

    .header-home-container .hamburger {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
        padding: 36px 30px;
        z-index: 2;
    }

    .header-home-container .hamburger span {
        position: relative;
        height: 2.5px;
        width: 40px;
        background-color: #fff;
        display: block;
        transition: background-color .2s ease-out;
    }

    .header-home-container .hamburger span:before,
    .header-home-container .hamburger span:after {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: #fff;
        display: block;
        transition: all .2s ease-out;
    }

    .header-home-container .hamburger span:before {
        top: -14px;
    }

    .header-home-container .hamburger span:after {
        top: 14.5px;
    }

    .header-home-container ul {
        display: none;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 80px;
        left: 0;
        width: 100%;
        background-color: #000;
        padding-top: 10px;
    }    

    .header-home-container ul li {
        margin-top: 50px;
        background-color: black;
        height: 80px;
        text-align: left;
        border-bottom: solid 1px rgb(52, 52, 52);
    }

    .header-home-container ul li:last-child {
        border-bottom: solid 1px #00818a;
    }
    
    .header-home-container ul.open {
        display: flex;
        transition: max-height 1s ease-out;
        height: 1000px;
    }

    @keyframes slideInMenu {
        0% {
          transform: translateX(-100%);
        }
        100% {
          transform: translateX(0);
        }
    }
      
      @keyframes slideOutMenu {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(-100%);
        }
    }
    
    .nav-links {
        max-height: 0;
        overflow: hidden; 
        transition: max-height 0.7s ease-in-out;
        animation-duration: 0.7s; 
    }
    
    .nav-links.open {
        max-height: 480px;
        background-color: #000;
        font-size: 25px;
        border: solid 1px #00818a;
        animation-name: slideInMenu; 
    }

    .header-home-container .offer-banner {
        height: 60px;
        font-size: 17px;
    }

    .offer-banner.hide-banner {
        display: none;
    }

    .header-home-container .offer-banner p {
        width: 70%;
    }

    .offer-banner p {
        animation: rotateBanner 10s linear infinite;
    }

    @keyframes rotateBanner {
        0% {
          transform: translateX(115%);
        }
        100% {
          transform: translateX(-115%);
        }
    } 

    .header-home-container ul li .header-links {
        margin-left: 40px;
    }
    
    .header-home-container ul li .header-links-contact {
        margin-left: 40px;
    }

    .header-home-container img {
        margin-left: 40px;
        width: 60px;
    }

    .header-home-container ul li:first-child {
        margin-top: 20px;
    }
}

/* IPhone */
@media screen and (max-width: 770px) { 

    .header-home-container nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 80px;
        margin: auto;
        padding: 0 20px;
        position: relative;
    }

    .header-home-container nav .meta::after {
        position: absolute;
        content: "Meta";
        color: #fff; 
        font-size: 25px; 
        font-weight: normal; 
        right: 85px;
        top: 25px;
    }

    .header-home-container nav .lens::after {
        position: absolute;
        content: "Lens";
        color: #00818a;
        font-size: 27px; 
        font-weight: normal; 
        right: 28px;
        top: 23px;
    }

    .header-home-container ul {
        display: none;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 80px;
        left: 0;
        width: 100%;
        background-color: #000;
        padding-top: 80px;
    }

    .navbar-toggler-icon {
        background-color: black;
    }

    .header-home-container ul li {
        margin: 0;
        width: 100%;
        padding: 10px 0;
    }

    .header-home-container ul li .header-links {
        color: #fff;
        text-align: center;
    }

    .header-home-container ul li .header-links:hover {
        color: #00818a;
    }

    .header-home-container .hamburger {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
        padding: 36px 30px;
        z-index: 2;
    }

    .header-home-container .hamburger span {
        position: relative;
        height: 2.5px;
        width: 40px;
        background-color: #fff;
        display: block;
        transition: background-color .2s ease-out;
    }

    .header-home-container .hamburger span:before,
    .header-home-container .hamburger span:after {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: #fff;
        display: block;
        transition: all .2s ease-out;
    }

    .header-home-container .hamburger span:before {
        top: -14px;
    }

    .header-home-container .hamburger span:after {
        top: 14.5px;
    }

    .header-home-container ul {
        display: none;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 80px;
        left: 0;
        width: 100%;
        background-color: #000;
        padding-top: 10px;
    }    

    .header-home-container ul li {
        margin-top: 50px;
        background-color: black;
        height: 80px;
        text-align: left;
        border-bottom: solid 1px rgb(52, 52, 52);
    }

    .header-home-container ul li:last-child {
        border-bottom: solid 1px #00818a;
    }
    
    .header-home-container ul.open {
        display: flex;
        transition: max-height 1s ease-out;
        height: 400px;
    }

    .header-home-container .offer-banner p {
        width: 120%;
    }

    @keyframes slideInMenu {
        0% {
          transform: translateX(-150%);
        }
        100% {
          transform: translateX(0);
        }
    }
      
      @keyframes slideOutMenu {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(-150%);
        }
    }
    
    .nav-links {
        max-height: 0;
        overflow: hidden; 
        transition: max-height 0.7s ease-in-out;
        animation-duration: 0.7s; 
    }
    
    .nav-links.open {
        max-height: 480px;
        background-color: #000;
        font-size: 25px;
        border: solid 1px #00818a;
        animation-name: slideInMenu; 
    }

    .header-home-container .offer-banner {
        height: 50px;
        font-size: 15px;
    }

    .offer-banner.hide-banner {
        display: none;
    }

    .offer-banner p {
        animation: rotateBanner 10s linear infinite;
    }

    @keyframes rotateBanner {
        0% {
          transform: translateX(90%);
        }
        100% {
          transform: translateX(-90%);
        }
    } 

    .header-home-container ul li .header-links {
        margin-left: 40px;
    }
    
    .header-home-container ul li .header-links-contact {
        margin-left: 40px;
    }

    .header-home-container img {
        margin-left: 40px;
        width: 60px;
    }

    .header-home-container ul li:first-child {
        margin-top: 20px;
    }
}