.about-us-container {
    margin-top: 150px;
    width: 100%;
    padding-top: 20px;
    background: rgb(0,129,138);
    background: linear-gradient(180deg, rgba(0,129,138,1) 0%, rgba(255,255,255,1) 60%);
    font-weight: normal;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about-us-banner {
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
    height: 100px;
    font-size: 40px;
    padding-top: 20px;
}

.about-us-banner h3 {
    font-weight: normal;
    margin: 0;
}

.about-us-p-container {
    text-align: center;
    max-width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about-img-container {
    display: flex;
    justify-content: space-between;
    padding-top: 100px;
}

.img-headshot {
    padding-right: 80px;
    padding-left: 80px;
}

.chloe-headshot img {
    width: 165px;
}

.luke-headshot img {
    width: 165px;
}


/* **********************************
******  CSS MEDIA QUERIES ********* 
************************************/

/* IMac - 21" */
@media screen and (min-width: 2000px){ 
    .about-us-banner {
        font-size: 50px;
    }

    .about-us-p-container {
        font-size: 23px;
    }    
}

/* IPad */
@media screen and (max-width: 900px) and (min-width: 770px){ 
    .about-us-container {
        margin-top: 200px;
        padding-top: 60px;
    }
    
    .about-us-banner {
        font-size: 40px;
        padding-top: 10px;
    }
    
    .about-us-p-container {
        font-size: 18px;
    }
    
    .about-img-container {
        padding-top: 80px;
    }

    .about-img-container h5 {
        font-size: 16px;
    }
    
    .img-headshot {
        padding-right: 80px;
        padding-left: 80px;
    }
    
    .chloe-headshot img {
        width: 150px;
    }
    
    .luke-headshot img {
        width: 150px;
    }

    .img-headshot {
        font-size: 15px;
    }

    .about-us-p-container p {
        font-size: 24px;
    }
}

/* IPhone */
@media screen and (max-width: 770px) {  
    .about-us-p-container p {
        font-size: 18px;
    }

    .about-img-container {
        padding-top: 60px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .img-headshot {
        padding-right: 10px;
        padding-left: 10px;
    }
    
    .chloe-headshot img {
        width: 150px;
    }

    .luke-headshot {
        padding-top: 60px;
    }
    
    .luke-headshot img {
        width: 150px;
    }

    .img-headshot {
        font-size: 15px;
    }

    .about-img-container h5 {
        font-size: 13px;
    }
}