.service-ad-container {
    margin: 0;
    margin-top: 50px;
    width: 100%;
    height: 450px;
}

.service-ad-flex {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.service-ad-text {
    margin-right: 80px;
    text-align: right;
    align-self: flex-start;
}

.service-ad-text h3 {
    color: #00818a;
    font-weight: normal;
    font-size: 40px;
    margin: auto;
    display: inline;
}

.service-ad-text h3:hover {
    color: black;
    cursor: pointer;
}

.service-ad-text .linked-header {
    padding-top: 80px;
}

.service-ad-text p.learn-more-p {
    font-weight: normal;
    font-size: 18px;
    display: inline;
    color: #00818a;
}

.service-ad-text p.learn-more-p:hover {
    color: black;
}

.service-ad-text .learn-more {
    padding-top: 50px;
    padding-bottom: 40px;
}

.service-ad-img {
    margin-left: 80px;
    max-width: 50%;
}

.service-ad-img img {
    max-width: 100%;
    max-height: 100%;
    width: 80%;
}

.fade-in {
    opacity: 0;
    transform: translateY(23px);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}
  
.fade-in-active {
    opacity: 1;
    transform: translateY(0);
}

/* **********************************
******  CSS MEDIA QUERIES ********* 
************************************/

/* IMac - 21" */
@media screen and (min-width: 2000px){ 
    .service-ad-img img {
        width: 60%;
    }

    .service-ad-text h3 {
        font-size: 50px;
    }

    p.home-service-paragraph {
        font-size: 23px;
    }

    .service-ad-text p.learn-more-p {
        font-size: 20px;
    }
}

/* IPad */
@media screen and (max-width: 900px) and (min-width: 800px){ 
    .service-ad-container {
        margin-top: 150px;
        height: 650px;
        max-width: 100%;
        display: flex;
        justify-content: center;
    }
    
    .service-ad-flex {
        height: 100%;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column-reverse;
        justify-content: space-evenly;
        align-items: center;
        max-width: 90%;
    }
    
    .service-ad-text {
        margin-right: 0px;
        text-align: center;
        align-self: flex-start;
    }
    
    .service-ad-text h3 {
        color: #00818a;
        font-weight: normal;
        font-size: 45px;
        margin: 0;
        padding-top: 50px;
    }
    
    .service-ad-text p {
        color: black;
        font-weight: normal;
        padding-top: 40px;
        font-size: 22px;
        padding-bottom: 30px;
        max-width: 80%;
        margin: auto;
    }
    
    .service-ad-img {
        margin: 0;
        max-width: 80%;
    }
    
    .service-ad-img img {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        margin: auto;
    }
}

@media screen and (max-width: 800px) and (min-width: 751px){ 
    .service-ad-container {
        margin-top: 120px;
        height: 650px;
        max-width: 100%;
        display: flex;
        justify-content: center;
    }
    
    .service-ad-flex {
        height: 100%;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column-reverse;
        justify-content: space-evenly;
        align-items: center;
        max-width: 90%;
    }
    
    .service-ad-text {
        margin-right: 0px;
        text-align: center;
        align-self: flex-start;
    }
    
    .service-ad-text h3 {
        color: #00818a;
        font-weight: normal;
        font-size: 40px;
        margin: 0;
        padding-top: 35px;
    }
    
    .service-ad-text p {
        color: black;
        font-weight: normal;
        padding-top: 40px;
        font-size: 18px;
        padding-bottom: 30px;
        max-width: 80%;
        margin: auto;
    }
    
    .service-ad-img {
        margin: 0;
        max-width: 90%;
    }
    
    .service-ad-img img {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        margin: auto;
    }
}

/* IPhone */
@media screen and (max-width: 750px) { 
    .service-ad-container {
        margin-top: 30px;
        height: 650px;
        max-width: 100%;
        display: flex;
        justify-content: center;
    }
    
    .service-ad-flex {
        height: 100%;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column-reverse;
        justify-content: space-evenly;
        align-items: center;
        max-width: 90%;
    }
    
    .service-ad-text {
        margin-right: 0px;
        text-align: center;
        align-self: flex-start;
    }
    
    .service-ad-text h3 {
        color: #00818a;
        font-weight: normal;
        font-size: 40px;
        margin: 0;
        padding-top: 35px;
    }
    
    .service-ad-text p {
        color: black;
        font-weight: normal;
        padding-top: 40px;
        font-size: 18px;
        padding-bottom: 30px;
        max-width: 80%;
        margin: auto;
    }
    
    .service-ad-img {
        margin: 0;
        max-width: 90%;
    }
    
    .service-ad-img img {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        margin: auto;
    }
}