.footer-container {
    max-width: 100%;
    width: 100%;
    background-color: black;
    height: 100px;
}

.footer-text {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 27px;
}

.footer-text h5 {
    margin: 0;
    color: white;
    font-weight: normal;
    font-size: 12px;
    padding-top: 5px;
}

.footer-text li {
    display: flex;
    align-items: flex-start;
}

.footer-text li h5:hover {
    color: #00818a;
}

.vertical-line {
    border-left: 1px solid #00818a;
    height: 25px;
    margin: 0 30px;
    margin-top: 9px;
}

.footer-container .author h5 {
    margin: 0;
    color: white;
    font-size: 12px;
    font-weight: normal;
    padding-top: 10px;
    text-align: right;
    margin-right: 40px;
}

.author a {
    text-decoration: none;
    color: #00818a;
}

.author a:hover {
    color: white;
}

/* **********************************
******  CSS MEDIA QUERIES ********* 
************************************/

/* IMac - 21" */
@media screen and (min-width: 2000px){ 
    .footer-container {
        height: 100px;
    }

    .footer-container .author h5 {
        font-size: 15px;
    }

    .footer-text h5 {
        font-size: 15px;
    }
}

/* IPad */
@media screen and (max-width: 900px) and (min-width: 770px){ 


}

/* IPhone */
@media screen and (max-width: 770px) { 
    .footer-container .author h5 {
        margin: auto;
        text-align: center;
        font-size: 10px;
    }
}