.home-contact-banner-container {
    width: 100%;
    max-width: 100%;
    height: 250px;
    background-color: rgba(0, 0, 0, 0.85);
    color: #ffffff;
    display: flex;
    justify-content: center;
    margin-top: 70px;
}

.home-contact-banner-text {
    width: 640px;
    padding-top: 85px;
}

.home-contact-banner-text h3 {
    margin: 0;
    font-weight: normal;
    font-size: 26px;
    text-align: center;
}

.home-contact-banner-text h2 {
    margin: 0;
    font-weight: normal;
    text-align: center;
    font-size: 42px;
}

.home-contact-banner-text h3 .fourK {
    color: #00818a;
}

/* **********************************
******  CSS MEDIA QUERIES ********* 
************************************/

/* IMac - 21" */
@media screen and (min-width: 2000px){ 
    
}

/* IPad */
@media screen and (max-width: 900px) and (min-width: 770px){ 
    
}

/* IPhone */
@media screen and (max-width: 770px) {  
    .home-contact-banner-container {
        height: 150px;
    }

    .home-contact-banner-text {
        padding-top: 50px;
    }

    .home-contact-banner-text h3 {
        font-size: 20px;
    }
    
    .home-contact-banner-text h2 {
        font-size: 30px;
    } 
}