.srvs-info-comp-container {
    width: 80%;
    margin: auto;
}

.srvs-info-comp-container h3 {
    padding-bottom: 10px;
    color: #00818a;
    font-size: 25px;
    font-weight: normal;
    margin-top: 60px;
}

.srvs-info-comp-container p {
    margin-bottom: 30px;
}

.srvs-info-container {
    padding-top: 100px;
    padding-bottom: 100px;
}

.srvs-info-inner-container {
    width: 80%;
    margin: auto;
    background-color: rgb(245, 245, 245);
    padding-top: 20px;
    padding-bottom: 50px;
}

.gallery-container {
    margin-bottom: 60px;
}

.react-3d-carousel .slider-container .slider-single .slider-right div {
    border: solid #00818a;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.react-3d-carousel .slider-container .slider-single .slider-left div {
    border: solid #00818a;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.services-img-container {
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
    margin-top: 50px;
}

img.services-pg {
    width: 60%;
}

/* **********************************
******  CSS MEDIA QUERIES ********* 
************************************/

/* IMac - 21" */
@media screen and (min-width: 2000px){ 
    .srvs-info-comp-container h3 {
        font-size: 40px;
    }
    
    .srvs-info-comp-container p {
        font-size: 23px;
    }

    ul.service-list li {
        font-size: 23px;
    }

    ul.service-list li::marker {
        font-size: 18px;
    }
}

/* IPad */
@media screen and (max-width: 900px) and (min-width: 770px){ 
    
    .srvs-info-comp-container h3 {
        padding-bottom: 0px;
        font-size: 24px;
        margin-top: 20px;
        margin-bottom: 0px;
    }
    
    .srvs-info-comp-container p {
        margin-bottom: 40px;
        font-size: 14px;
    }
    
    .srvs-info-container {
        padding-top: 40px;
        padding-bottom: 70px;
    }
    
    .srvs-info-inner-container {
        width: 90%;
        padding-top: 20px;
        padding-bottom: 30px;
    }
    
    ul.service-list li {
        font-size: 14px;
    }

    ul.service-list li::marker {
        font-size: 10px;
    }
  
    ul.service-list {
        margin-bottom: 40px;
    }

    p.first {
        margin-bottom: 20px;
    }

    p.child {
        margin-bottom: 40px;
    }

    .services-img-container {
        margin-bottom: 80px;
        margin-top: 50px;
    }
    
    img.services-pg {
        width: 80%;
    }
}

/* IPhone */
@media screen and (max-width: 770px) { 

    .srvs-info-comp-container h3 {
        padding-bottom: 0px;
        font-size: 24px;
        margin-top: 20px;
        margin-bottom: 0px;
    }
    
    .srvs-info-comp-container p {
        margin-bottom: 40px;
        font-size: 14px;
    }
    
    .srvs-info-container {
        padding-top: 40px;
        padding-bottom: 70px;
    }
    
    .srvs-info-inner-container {
        width: 90%;
        padding-top: 20px;
        padding-bottom: 30px;
    }
    
    ul.service-list li {
        font-size: 14px;
    }

    ul.service-list li::marker {
        font-size: 10px;
    }
  
    ul.service-list {
        margin-bottom: 40px;
    }

    p.first {
        margin-bottom: 20px;
    }

    p.child {
        margin-bottom: 40px;
    }

    .services-img-container {
        margin-bottom: 80px;
        margin-top: 50px;
    }
    
    img.services-pg {
        width: 90%;
    }
}