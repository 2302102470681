.pricing-info-container {
    align-content: center;
    padding-bottom: 100px;
}

.pricing-info-background {
    width: 80%;
    margin: auto;
    background-color: rgb(245, 245, 245);
    margin-top: 100px;
    padding-bottom: 20px;
}

.pricing-info-container h3 {
    text-decoration: none;
    padding-left: 13%;
    padding-top: 30px;
    font-size: 22px;
    color: #00818a;
    font-weight: normal;
}

.pricing-info-container h3:first-of-type {
    padding-top: 60px;
}

.pricing-info-container h5 {
    padding-left: 15%;
    font-size: 18px;
}

h5.subheading {
    padding-left: 15%;
    font-size: 16px;
    margin: auto;
    font-weight: normal;
}

.pricing-info-container table {
    width: 70%;
    border-collapse: collapse;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 50px;
}

td, th {
    border: 1px solid #b1b1b1;
    text-align: left;
    padding: 10px;
    width: 33%;
}

.call-out-table {
    width: 50%;
}

.pricing-info-container h6 {
    font-weight: normal;
    padding-right: 12%;
    margin-top: 80px;
    text-align: right;
}

h5.subheading {
    max-width: 70%;
    margin-left: 0px;
}

/* **********************************
******  CSS MEDIA QUERIES ********* 
************************************/

/* IMac - 21" */
@media screen and (min-width: 2000px){ 
    .pricing-info-container h3 {
        font-size: 40px;
    }

    .pricing-info-container h5 {
        font-size: 24px;
    }
    
    h5.subheading {
        font-size: 23px;
    }

    h6 {
        font-size: 14px;
    }

    td, th {
        font-size: 20px;
    }
}

/* IPad */
@media screen and (max-width: 900px) and (min-width: 770px){ 
    h5.subheading {
        max-width: 75%;
        margin-left: 0px;
    }
}

/* IPhone */
@media screen and (max-width: 770px) { 
    .pricing-info-container {
        padding-bottom: 50px;
        text-align: center;
    }
    
    .pricing-info-background {
        width: 90%;
        margin-top: 60px;
    }
    
    .pricing-info-container h3 {
        padding-left: 0%;
        padding-top: 0px;
        font-size: 18px;
    }
    
    .pricing-info-container h3:first-of-type {
        padding-top: 40px;
    }
    
    .pricing-info-container h5 {
        padding-left: 0%;
        font-size: 16px;
    }
    
    h5.subheading {
        padding-left: 15%;
        font-size: 14px;
        margin: auto;
        font-weight: normal;
    }
    
    .pricing-info-container table {
        width: 70%;
        border-collapse: collapse;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 50px;
    }
    
    td, th {
        border: 1px solid #b1b1b1;
        text-align: left;
        padding: 5px;
        width: 33%;
    }
    
    .call-out-table {
        width: 50%;
    }
    
    .pricing-info-container h6 {
        font-weight: normal;
        padding-right: 8%;
        margin-top: 80px;
        text-align: right;
    }
    
    h5.subheading {
        max-width: 70%;
        margin-left: 0px;
    }
}