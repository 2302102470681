.logo-banner-container {
    margin: 0;
    width: 100%;
    background-color: black;
    height: 400px;
    margin-top: 65px;
}

.logo-banner-flex {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo-banner-header {
    margin-left: 80px;
}

.logo-banner-header h1 {
    color: white;
    font-weight: normal;
    font-size: 70px;
    margin: 0;
}

.logo-banner-header h1 .Lens {
    color: #00818a;
}

.logo-banner-header h5 {
    color: white;
    font-weight: normal;
    margin: 0;
    font-size: 20px;
    margin-left: 8px;
}

.logo-banner-img {
    margin-right: 60px;
}
  
.logo-banner-img img {
    max-width: 100%;
    max-height: 100%;
    width: 70%;
}

/* **********************************
******  CSS MEDIA QUERIES ********* 
************************************/

/* IMac - 21" */
@media screen and (min-width: 2000px){ 
    .logo-banner-header h5 {
        font-size: 23px;
        margin-left: 5px;
    }
}

/* IPad */
@media screen and (max-width: 900px) and (min-width: 770px){ 
    .logo-banner-container {
        margin-top: 57px;
    }
    
    .logo-banner-img {
        margin-right: 0px;
        display: flex;
        justify-content: center;
    }

    .logo-banner-img img {
        padding-left: 0px;
        width: 50%;
        align-items: center;
    }

    .logo-banner-header h5 {
        font-size: 16px;
        margin-left: 3px;
    }
}

/* IPhone */
@media screen and (max-width: 770px) { 
    .logo-banner-container {
        margin: 0;
        width: 100%;
        background-color: black;
        height: 300px;
        margin-top: 50px;
    }
    
    .logo-banner-flex {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .logo-banner-header {
        display: none;
    }
    
    .logo-banner-img {
        margin: auto;
        display: flex;
        justify-content: center;
    }
      
    .logo-banner-img img {
        max-width: 60%;
        max-height: 60%;
    }

    .logo-banner-header h1.TsCs {
        font-size: 60px;
        text-align: center;
    }
}